import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const FileManagerPage = () => (
  <div>
    <SEO 
        title="Sheep Hosting Bay File Manager" 
        description="We give you a drag & drop File Manager, which features a list of easy–to–work–with 
        applications like an inbuilt image viewer and a variety of file editors."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title">The <span className="block text-indigo-600 xl:inline">Sheephostingbay</span> File Manager <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">Just forget about FTP clients with the File Manager</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/filemanager/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/file-manager-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> We give you a drag & drop File Manager, which features a list of easy–to–work–with 
                 applications like an inbuilt image viewer and a variety of file editors. You could make speedy uploads, archive/unarchive data 
                 files, adjust permissions and a lot more. <br/><br/>
                 <span role="img" aria-label="emoji">✨ </span>The software tools are well–organized and simple to get through with big action 
                 buttons and right–click context navigation. Find out more about the various instruments and functions integrated in the 
                 Sheephostingbay File Manager.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/drag-n-drop-file-uploads.webp" className="w-60" alt="Drag ’n’ drop file uploads" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Drag ’n’ drop file uploads</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    The most effective way to add data files<br/><br/>
                                    Uploading files with an FTP client is an obsolete and really insecure way of shifting data files from your 
                                    personal computer to a hosting server. This is why, we have included drag & drop file publish support in 
                                    the File Manager. Just drag the files that you need to publish from your desktop into your browser and 
                                    they will start to upload in your website hosting profile over a protected SSL communication.
                                    <br/><br/>
                                    You don’t have to install any extra computer software, web browser plug–ins or programs. The drag & drop 
                                    feature operates equally well on Windows, Linux or Mac.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Right–click context menus</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    View all the easily accessible file management options with just a click<br/><br/>
                                    Thanks to the right–click menus that we have included in the File Manager, you are able to manage your files 
                                    incredibly easy. Just click on a file or folder and from the panel which will show up, find the action that 
                                    you’d like. It is possible to rename, copy, move, download, edit, preview, archive, unarchive and delete 
                                    any picked file. If you click a folder, you will also see the option to make a completely new sub–folder 
                                    or file inside it.
                                    <br/><br/>
                                    If you choose numerous files or folders, you will be able to apply the same procedures to all of them 
                                    concurrently.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/right-click-context-menus.webp" className="w-60" alt="Right–click context menus" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/file-archive-unarchive.webp" className="w-60" alt="Archive/Unarchive files" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Archive/Unarchive files</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Control archives with just a mouse–click<br/><br/>
                                    The File Manager offers an integrated archive/unarchive solution that can help you submit big archives and extract 
                                    them inside your web hosting account in seconds. The moment compressing a file or directory, it will be easy to 
                                    select the title of the archive that will be created and its particular extension – .ZIP, .RAR or .TAR.GZ. 
                                    Additionally, you’ll be able to compress already present files or folders with just a click of the mouse.
                                    <br/><br/>
                                    The archive/unarchive option offered by the File Manager allows you to work with big files straight from your Web 
                                    Control Panel. No need to make use of any third–party software for instance an FTP client so as to upload a big 
                                    file onto your web hosting account or to download an archive on your desktop.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Online file editors</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    The most rapid way for you to revise your files<br/><br/>
                                    Our File Manager makes it possible for you to change your files directly on the server without the need to download, 
                                    modify and then re–upload them. It’s possible to change your files instantly on the net, using our built in editors. 
                                    We provide a code editor with syntax emphasizing for your .PHP or .JS files, a WYSIWYG editor for HTML files, in 
                                    addition to a regular text editor for the more experienced customers.
                                    <br/><br/>
                                    You have access to every single file editor making use of the right–click context menus. All of the corrections 
                                    produced to the files will be shown instantaneously online – you’re able to directly preview them within the 
                                    Internet browser.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/online-file-editors.webp" className="w-60" alt="Online file editors" />
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/logical-folder-structure.webp" className="w-60" alt="A logical folder structure" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Whois Privacy Protection</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    All your files organized in a straightforward manner<br/><br/>
                                    All the files in your web hosting account are structured into folders. Each and every web host does have its main folder, 
                                    that employs the file hierarchy which you have pre–set. Using this method, it is really not difficult to control all 
                                    your hosts in one place with no chance of getting lost or puzzled.
                                    <br/><br/>
                                    By default, you will notice all the hosts mentioned in a table and when you mouse–click once over a specific host, you’ll 
                                    see each of the sub–folders and files related to it. By clicking two times, you are going to visit a web page that’s 
                                    devoted to that host only. An additional way to open the folder related to a particular host and then to use it 
                                    exclusively, is using the drop–down menu towards the top of the primary File Manager file table.
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A user–friendly interface</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    A File Manager intended for real people<br/><br/>
                                    Managing your files is instinctive and uncomplicated when using the Sheephostingbay Web Control Panel. You are going to 
                                    try a file management environment which is not far from your desktop computer’s file explorer. You can drag & drop 
                                    files and manage them with a right mouse–click. Thanks to the built–in review solution, you can observe photos 
                                    in the same way they will show up on the Internet, and throughout the integrated file editors you can create 
                                    transformations to your files instantly.
                                    <br/><br/>
                                    If you want guidance handling your files, it is possible to take a look at the huge assortment of articles and how–to 
                                    training videos, that you can access using a mouse–click.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/user-friendly-interface.webp" className="w-60" alt="A user–friendly interface" />
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/work-with-multiple-files.webp" className="w-60" alt="Work with multiple files" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Work with multiple files</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Modify various files at the same time<br/><br/>
                                    You can make use of numerous files all at once making use of the multi–selection capability of the Sheephostingbay 
                                    File Manager. Everything you should do is just press the Control key and then click on the files or directories 
                                    you want to pick. If you’d like to select a number of files/folders in a row at once, click the first 
                                    file/folder, press the Shift key and then pick the final file or folder in the selection.
                                    <br/><br/>
                                    When you have chosen the desired files/folders, you can drag them to a distinct place, or use a variety of actions 
                                    to them making use of right–click contextual menu as well as the buttons at the top of the file table.
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Quick & simple password protection options</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    The easiest way to protect your data<br/><br/>
                                    There is a single standard technique to shield a directory using a security password – by generating an .htaccess 
                                    file. If, however, you are not technologically competent enough, you will have trouble carrying it out without 
                                    any help. To generate things easy for you, we’ve designed an easy–to–work–with password protection user 
                                    interface to the File Manager readily available within the Web Control Panel.
                                    <br/><br/>
                                    To employ this option, simply right–click on a directory and choose Password Protection. After that fill out the 
                                    user name and the pass word that you’ll want to implement and click on the button. That’s all. Now the 
                                    particular directory is concealed by the standard users and will be available only to approved users.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/quick-simple-password-protection.webp" className="w-60" alt="Quick & simple password protection options" />
                            </div>
                        </div>   
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default FileManagerPage
